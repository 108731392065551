/* Apply a font from Google Fonts */

body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

/* Basic grid layout */
.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 20px;
  padding: 20px;
}

/* Modern buttons */
.button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

/* Spacing and alignment */
.card {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

